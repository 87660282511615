import { useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'swiper/swiper-bundle.min.css'
import '@/public/static/css/wrap.css'
import '@/public/static/css/home.css'
import '@/public/static/css/custom.css'
import Router, { useRouter } from 'next/router'
import NProgress from 'nprogress' //nprogress module
import 'nprogress/nprogress.css' //styles of nprogress
import { CartProvider } from '@/contexts/cart'
import Loader from 'components/loading/loader'
import { AuthProvider } from '@/contexts/auth'
import { DataProvider } from '@/contexts/dataContext'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import * as gtag from '@/lib/gtag'
import { v4 as uuidv4 } from 'uuid'

import Cookies from 'js-cookie'
Router.events.on('routeChangeStart', () => NProgress.start())
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

const App = ({ Component, pageProps }) => {
  const router = useRouter()
  const [pageLoading, setPageLoading] = useState(false)
  const uuid = Cookies.get('uuid')

  useEffect(() => {
    if (!uuid) {
      Cookies.set('uuid', uuidv4())
    }
  }, [])

  useEffect(() => {
    const handleRouteChange = (url) => {
      gtag.pageview(url)
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  useEffect(() => {
    const handleStart = () => {
      setPageLoading(true)
    }
    const handleComplete = () => {
      setPageLoading(false)
    }

    router.events.on('routeChangeStart', handleStart)
    router.events.on('routeChangeComplete', handleComplete)
    router.events.on('routeChangeError', handleComplete)
  }, [router])

  return (
    <>
      {pageLoading && <Loader />}
      <AuthProvider>
        <CartProvider>
          <DataProvider>
            <Component {...pageProps} />
            <ToastContainer
              position="top-right"
              autoClose={1000}
              hideProgressBar={true}
              newestOnTop={true}
              draggable={true}
              pauseOnVisibilityChange
              closeOnClick
              pauseOnHover
              progress
            />
          </DataProvider>
        </CartProvider>
      </AuthProvider>
    </>
  )
}

export default App
