import axios from 'axios'
import jwt_decode from 'jwt-decode'
import Cookies from 'js-cookie'
import dayjs from 'dayjs'

export const axiosInstance = axios.create({
  baseURL: process.env.BASE_URL,
})

axiosInstance.interceptors.request.use(
  async (req) => {
    // const { setLogout } = useAuthState()

    const accessToken = Cookies.get('banana_token')
    const refreshToken = Cookies.get('refresh_token')

    // if (accessToken) {
    //   req.headers['Authorization'] = 'Bearer ' + token;
    // } else {
    //   return req;
    // }
    if (!accessToken) return req

    const user = jwt_decode(accessToken)
    // console.log(dayjs.unix(user.exp).diff(dayjs(), "minutes"))

    const isExpired = dayjs.unix(user.exp).diff(dayjs(), 'hours') <= 1

    if (!isExpired) return req

    try {
      const res = await axios.post(
        `${process.env.BASE_URL}shop/token/refresh`,
        {
          refresh_token: refreshToken ? refreshToken : null,
        }
      )
      Cookies.set('banana_token', res?.data.token)
      Cookies.set('refresh_token', res?.data.refresh_token)
    } catch (err) {
      Cookies.remove('banana_token')
      Cookies.remove('refresh_token')
      window.location.reload()
    }

    // req.headers['Authorization'] = `Bearer ${response.data.token}`;

    return req
  },
  (error) => {
    Promise.reject(error)
  }
)

class BaseApi {
  constructor(accessToken, subPath) {
    this.config = {}
    if (accessToken) {
      this.config.headers = {
        authorization: `Bearer ${accessToken}`,
      }
    }
    // this.apiUrl = process.env.BASE_URL + subPath
    this.apiUrl = subPath
  }

  getAll() {
    return axiosInstance.get(`${this.apiUrl}?enabled=true`)
  }

  getBrandsAll(page, perPage, name) {
    return axiosInstance.get(
      `${this.apiUrl}/by-letter/${
        name ? `${name}` : ''
      }?order[name]=ASC&page=${page}&limit=${perPage}${
        name ? `&name=${name}` : ''
      }`
    )
  }

  getBrandsSpell(name) {
    return axiosInstance.get(
      `${this.apiUrl}/by-letter/${name ? `${name}` : ''}`
    )
  }

  getAddresses() {
    return axiosInstance.get(this.apiUrl, this.config)
  }

  getOrderAll(pagination, perPage) {
    return axiosInstance.get(
      `${this.apiUrl}?page=${pagination}&limit=${perPage}&order[id]=desc`,
      this.config
    )
  }

  getCurrentUser() {
    return axiosInstance.get(this.apiUrl, this.config)
  }

  getCartsAll(orderToken) {
    return axiosInstance.get(`${this.apiUrl}/${orderToken}`, this.config)
  }

  getOrderDetail(token) {
    return axiosInstance.get(`${this.apiUrl}/${token}`, this.config)
  }

  getWishListAll() {
    return axiosInstance.get(
      `${this.apiUrl}?channelCode=${process.env.BASE_CHANNEL_CODE}`,
      this.config
    )
  }

  // getFeaturedProducts() {
  //   return axiosInstance.get(`${this.apiUrl}?isFeatured=true&page=1&itemsPerPage=30`)
  // }

  getFlashSaleProducts() {
    return axiosInstance.get(
      `${this.apiUrl}?productTaxons.taxon.code=flash_sale`
    )
  }

  getSaleProducts(page, perPage, sortType) {
    return axiosInstance.get(
      `${this.apiUrl}/active/promotion?page=${page}&limit=${perPage}&${sortType}`
    )
  }

  // getFeaturedBrands() {
  //   return axiosInstance.get(`${this.apiUrl}?isFeatured=true&page=1&itemsPerPage=30`)
  // }

  getCategories() {
    return axiosInstance.get(this.apiUrl)
  }

  getFeaturedCategories() {
    return axiosInstance.get(`${this.apiUrl}-by-feature`)
  }

  getThreeBanners() {
    return axiosInstance.get(
      `${this.apiUrl}?enabled=true&taxons.code=three_banner`
    )
  }

  getMainBanner() {
    return axiosInstance.get(
      `${this.apiUrl}?enabled=true&taxons.code=home_banner`
    )
  }

  getTravelBanners() {
    return axiosInstance.get(
      `${this.apiUrl}?enabled=true&taxons.code=Travel_banners`,
      {
        cache: {
          maxAge: 30 * 1000, // Cache for 15 minutes
          revalidate: true, // Enable revalidation
          readOnError: true, // Read from cache on error
        },
      }
    )
  }

  getFourBanner() {
    return axiosInstance.get(
      `${this.apiUrl}?enabled=true&taxons.code=four_banner`
    )
  }
  getBrandBanner() {
    return axiosInstance.get(`${this.apiUrl}?enabled=true&taxons.code=SALE`)
  }
  getBrandBannerTech() {
    return axiosInstance.get(`${this.apiUrl}?enabled=true&taxons.code=TOPTECH`)
  }
  getBrandBannerBeau() {
    return axiosInstance.get(
      `${this.apiUrl}?enabled=true&taxons.code=TOPCOSMETIC`
    )
  }
  getLoanBanner() {
    return axiosInstance.get(`${this.apiUrl}?enabled=true&taxons.code=loan`)
  }
  getScentBanner() {
    return axiosInstance.get(`${this.apiUrl}?enabled=true&taxons.code=12`)
  }
  getMainCategoryBanner() {
    return axiosInstance.get(
      `${this.apiUrl}?enabled=true&taxons.code=MainCategory`
    )
  }

  getMobileFourBanners() {
    return axiosInstance.get(`${this.apiUrl}?enabled=true&taxons.code=Mobile-4`)
  }

  getCategoryDetail(url) {
    return axiosInstance.get(`${this.apiUrl}/${url}`)
  }

  getLendPromotionBanners() {
    return axiosInstance.get(
      `${this.apiUrl}?enabled=true&taxons.code=Ursdag-banner`
    )
  }

  getBlackFridayBackgrounds() {
    return axiosInstance.get(
      `${this.apiUrl}?enabled=true&taxons.code=BlackFridayBackground`
    )
  }

  getBlackFridayProducts() {
    return axiosInstance.get(
      `${this.apiUrl}?enabled=true&taxons.code=BlackFridayProduct`
    )
  }

  getSwowroomTaxonBanners() {
    return axiosInstance.get(
      `${this.apiUrl}?enabled=true&taxons.code=Showroom-Homepage`
    )
  }

  getWideBanner() {
    return axiosInstance.get(
      `${this.apiUrl}?enabled=true&taxons.code=wide_banner`
    )
  }

  getLongBanner() {
    return axiosInstance.get(
      `${this.apiUrl}?enabled=true&taxons.code=long_banner`
    )
  }

  getFlashBanner() {
    return axiosInstance.get(
      `${this.apiUrl}?enabled=true&taxons.code=flash_sale_banner`
    )
  }

  // getSlideBrandBanner() {
  //   return axiosInstance.get(`${this.apiUrl}?enabled=true&taxons.code=home_slide_brand`)
  // }

  getHomeCollections() {
    return axiosInstance.get(
      `${process.env.BASE_URL}shop/taxon-products/by-code/collection`
    )
  }
  getHomeCashback() {
    return axiosInstance.get(
      `${process.env.BASE_URL}shop/taxon-products/by-code/cashback`
    )
  }
  getTwoSquareBanners1() {
    return axiosInstance.get(
      `${process.env.BASE_URL}shop/taxon-products/by-code/two_wide_banner_1`
    )
  }
  getTwoSquareBanners2() {
    return axiosInstance.get(
      `${process.env.BASE_URL}shop/taxon-products/by-code/two_wide_banner_2`
    )
  }

  // getHomeFlashSale() {
  //   return axiosInstance.get(
  //     `${process.env.BASE_URL}shop/taxon-products/by-code/discount`
  //   )
  // }

  getFeaturedBrandBanner() {
    return axiosInstance.get(
      `${process.env.BASE_URL}shop/taxon-products/by-code/home_slide_brand`
    )
  }
  getSalesBanner() {
    return axiosInstance.get(
      `${this.apiUrl}?enabled=true&taxons.code=sales_banner`
    )
  }
  getProductBySlug(code) {
    return axiosInstance.get(`${this.apiUrl}/${code}`)
  }

  getBrandByProducts(page, perPage, id, sortType) {
    return axiosInstance.get(
      `${this.apiUrl}/${id}/products?page=${page}&order[name]=ASC&itemsPerPage=${perPage}&${sortType}`
    )
  }
  getCollectionAll() {
    return axiosInstance.get(
      `${this.apiUrl}/collection/children?page=1&itemsPerPage=30`
    )
  }
  getCashbackAll() {
    return axiosInstance.get(
      `${this.apiUrl}/cashback/children?page=1&itemsPerPage=30`
    )
  }
  GetProductByCategoryId(
    page,
    perPage,
    categoryFilter,
    sortByFilter,
    brandFilter,
    qFilter,
    minPriceFilter,
    maxPriceFilter
  ) {
    return axiosInstance.get(
      `${
        process.env.BASE_URL
      }shop/search?page=${page}&limit=${perPage}${categoryFilter}${sortByFilter}${brandFilter}${qFilter}${
        minPriceFilter && minPriceFilter
      }${maxPriceFilter && maxPriceFilter}`
    )
  }

  createRefreshToken(data) {
    return axiosInstance.post(`${process.env.BASE_URL}shop/token/refresh`, data)
  }
}

export default BaseApi
